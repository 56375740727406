<script lang="ts">
    import api from '../services/api.js';
    import user from '../services/user.js';
    import Page from '../components/page.svelte';
    import { smallScreen } from '../util/screen.js';

    let username = '';
    let signedIn = user.signedIn;
    let signingIn = false;
    let serverErrorMessage = '';
    let needUsername = false;
    let enteredEmail = false;
    let enteredCode = false;
    let marketing = false;

    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get('email');;
    const confirmParam = url.searchParams.get('confirm');
    const redir = url.searchParams.get('redir');
    let email = emailParam;
    let confirm  = confirmParam;

    async function onSubmitEmail() {
        try {
            signingIn = true;
            serverErrorMessage = '';
            await api.signup(email, redir);
            enteredEmail = true;
        } catch (e: any) {
            serverErrorMessage = e.message;
        }

        signingIn = false;
    }

    async function onSubmitConfirm() {
        confirm = confirm.toUpperCase();
        login(email, confirm, '');
    }

    async function onSubmitUsername() {
        login(email, confirm, username);
    }

    function redirect() {
        if (redir) {
            window.location.href = decodeURIComponent(redir);
        } else if ($smallScreen) {
            window.location.href = '/docs';
        } else {
            window.location.href = '/edit';
        }
    }

    async function login(email: string, confirm: string, username: string) {
        try {
            signingIn = true;
            serverErrorMessage = '';
            const trialId = user.getTrialId();
            const [uname, jwt] = (await api.login(email, confirm, username, trialId, marketing)).split(',');
            user.saveCreds(jwt, uname);
            window.localStorage.removeItem('trial-id');
            signedIn = true;
            redirect();
        } catch (e: any) {
            if (e.message === 'Error: Not registered.') {
                needUsername = true;
                enteredCode = true;
            } else {
                serverErrorMessage = e.message;
            }
        }

        signingIn = false;
    }

    if (!signedIn && email && confirm) {
        login(email, confirm, username);
    }

    if (signedIn) {
        redirect();
    }
</script>

<Page>
    <div class="flex col rounded-20 p-30" style="max-width: 500px; width: 98%;">
        <h2>Get Started</h2>
        {#if signedIn}
            <div class="p-20 flex col gap-20">
                <p>You are now signed in to Virtual Maker.</p>
                <button class="primary-button" on:click={redirect}>Continue</button>
            </div>
        {:else if signingIn}
            <div class="p-20">Signing you in...</div>
        {:else if serverErrorMessage !== "Invalid or expired confirmation code."}
            {#if !emailParam && !enteredEmail}
                <form class="flex col gap-20 mt-20" on:submit|preventDefault={onSubmitEmail}>
                    <label for="email">Email</label>
                    <input id="email" type="email" bind:value={email} required>
                    <button class="primary-button mt-20" style="align-self: end">Continue</button>
                </form>
            {:else if !confirmParam && !enteredCode}
                <form class="flex col gap-20 mt-20" on:submit|preventDefault={onSubmitConfirm}>
                    <label for="confirm">We sent a <b>confirmation code</b> to your email. Please enter it here.</label>
                    <input id="confirm" style="text-transform: uppercase;" pattern={"[0-9AaBbCcDdEeFf]{6}"} bind:value={confirm} required>
                    <button class="primary-button mt-20" style="align-self: end">Continue</button>
                </form>
            {:else if needUsername}
                <form class="flex col gap-20 mt-20" on:submit|preventDefault={onSubmitUsername}>
                    <label for="username">Pick a username (5-12 alphanumeric characters)</label>
                    <input id="username" pattern={"[A-Za-z0-9\-_]{5,12}"} bind:value={username} required>
                    <div class="flex align-center">
                        <input id="terms-checkbox" required type="checkbox">
                        <label class="sz-14" for="terms-checkbox">I have read and accept the <a href="/privacy" target="_blank">Privacy Policy</a> and <a href="/terms" target="_blank">Terms of Use</a>.</label>
                    </div>
                    <div class="flex align-center">
                        <input id="marketing-checkbox" type="checkbox" bind:checked={marketing}>
                        <label class="sz-14 line-height-1-5" for="marketing-checkbox">Let me know about new features and products from<br> Virtual Maker.</label>
                    </div>
                    <button class="primary-button mt-20" style="align-self: end">Continue</button>
                </form>
            {/if}
        {/if}

        <div class="error">{serverErrorMessage}</div>
        {#if serverErrorMessage === 'Invalid or expired confirmation code.'}
            <button class="primary-button" on:click={() => window.location.href = '/login'}>Try Again</button>
        {/if}
    </div>
</Page>

<style>
    input {
        font-size: 20px;
        padding: 10px;
        font-family: Arial, Helvetica, sans-serif;
        border: 1px solid gray;
    }

    input:invalid {
        border: 1px solid red;
    }

    .error {
        margin-top: 10px;
        color: red;
        max-width: 100%;
    }

    form {
        display: inline-flex;
        flex-flow: column;
    }

    a {
        color: #21a6f0;
    }

    input[type=checkbox] {
        transform: scale(1.5);
        margin-right: 15px;
    }
</style>
